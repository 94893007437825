.App {
  text-align: center;
}

.App-header {
  background-color: black;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-footer {
  background-color: #2a3439;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(7px + 2vmin);
  color: white;
}

.App-appStoreLink {
  display:inline-block;
  overflow:hidden;
  background:url(https://linkmaker.itunes.apple.com/en-us/badge-lrg.svg?releaseDate=2020-05-07&kind=iossoftware&bubble=ios_apps) no-repeat;
  width:135px;
  height:40px;
}
